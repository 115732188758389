import { baseURL } from "@/CONSTANT";

const postOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
};

// 获取图形验证码
export const getCaptchaImage = () => {
    const url = `${baseURL}/captchaImage`;
    return fetch(url).then(res => res.json());
}

// 发送时间戳
export const sendTimeStamp = () => {
    const url = `${baseURL}/sockjs-node/info?t=${new Date().getTime()}`;
    return fetch(url).then(res => res.json());
}

// 获取手机短信验证码(注册)
export const getRegisterPhoneVeriCode = (mobile, email) => {
    const url = `${baseURL}/generateMobileCode?action=register&mobile=${mobile}&email=${email}`;
    return fetch(url).then(res => res.json());
}

// 提交注册
export const submitRegister = (form) => {
    const url = `${baseURL}/mobileRegister`;
    let options = Object.assign({}, postOptions);
    options.body = JSON.stringify(form);
    return fetch(url, options).then(res => res.json());
}

// 获取手机短信验证码(登录)
export const getPhoneVeriCode = (phoneNumber) => {
    const url = `${baseURL}/generateMobileCode?mobile=${phoneNumber}`;
    return fetch(url).then(res => res.json());
}

// 登录后通过token换取用户信息
export const tokenGetUserInfo = (token) => {
    const url = `${baseURL}/getInfo`;
    const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        }
    };
    return fetch(url, options).then(res => res.json());
}