import Vue from 'vue'
import App from './App.vue'
import router from './router' 
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import store from './store';
import '@/styles/index.less'; // global css
import './rem.js';
import VideoPlayer from 'vue-video-player';
import 'video.js/dist/video-js.css'; // 引入 Video.js 样式
import 'vue-video-player/src/custom-theme.css'; // 引入 vue-video-player 样式
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import './core/lazy_use';
import './core/use';
import bus from './bus.js';


Vue.use(VideoPlayer);
Vue.use(Antd);

Vue.use(ElementUI);
Vue.config.productionTip = false;

Vue.prototype.bus = bus;


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
