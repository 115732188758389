<template>
    <div class="box">
        <div class="warp">
            <div class="title flex" :style="isUserLogin && 'padding-right: 20px;'">
                <div class="text-login">
                    新型工业化数字基础设施公共服务平台
                </div>
                <div
                    :class="[
                        { 'act-btn': 
                        item.routerName === $route.name
                        || (item.routerName === 'IndustryClasses' && $route.name === 'IndustryVideoDetails')
                        || (item.routerName === 'IndustryClasses' && $route.name === 'IndustrySearch')
                        || (item.routerName === 'ProductSupermarket' && $route.name === 'ProductInformation')
                    },
                        'title-btn'
                    ]"
                    v-for="(item, index) in titleArr"
                    :key="index"
                    @click="clickRoutr(item)"
                    @blur="blurSelector()"
                >
                    {{ item.name }}
                    <div class="downSelector" v-if="showSelector && item.routerName === 'publicServices'">
                        <a class="link" @click="openNewPage(1)">长三角企业数字化转型公共服务平台</a>
                        <a class="link" @click="openNewPage(2)">在线自评估</a>
                    </div>
                </div>

                <div v-if="!isUserLogin" class="btn flex">
                    <div @click="toLogin()" style="cursor: pointer;">登录</div>
                    <div @click="toRegister()" class="login-btn">免费注册</div>
                </div>

                <div v-if="isUserLogin" class="btn flex">
                    <div style="margin-right: 12px;">
                        <img :src="userInfo.avatar ? userInfo.avatar : require('@/assets/icons/user1.png')" alt="">
                    </div>
                    <el-dropdown @command="handleCommand">
                        <span class="el-dropdown-link">
                            <span style="overflow: hidden;">{{ userInfo.userName || '企业管理员'}}</span>
                            <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <!-- <el-dropdown-item command="a">企业认证</el-dropdown-item> -->
                            <el-dropdown-item command="b" style="text-align: center;">登出</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Cookies from 'js-cookie';
import { tokenGetUserInfo } from '@/services/register-login';
import { baseURL2, baseURL3 } from '@/CONSTANT';
export default {
    name: 'headTitle',
    data() {
        return {
            titleArr: [
                {
                    name: '首页',
                    key: '首页',
                    index: 0,
                    routerName: 'HomePage'
                },
                {
                    name: '政策动态',
                    key: '政策动态',
                    index: 1,
                    routerName: 'PoliciesDynamicState'
                },
                {
                    name: '产品超市',
                    key: '产品超市',
                    index: 2,
                    routerName: 'ProductSupermarket'
                },
                {
                    name: '解决方案',
                    key: '解决方案',
                    index: 3,
                    routerName: 'ProblemsSolution'
                },
                {
                    name: '公共服务',
                    key: '公共服务',
                    index: 4,
                    routerName: 'publicServices'
                },
                {
                    name: '工业课堂',
                    key: '工业课堂',
                    index: 5,
                    routerName: 'IndustryClasses'
                },
                {
                    name: '反馈建议',
                    key: '反馈建议',
                    index: 5,
                    routerName: 'FeedBack'
                }
            ],
            actionsIndex: 0, //首页
            isUserLogin: false,
            userInfo: {},
            isLogout: false,
            showSelector: false,
            isPublicServicesTabShow: false
        }
    },
    mounted() {
        if (window.name) {
            this.isUserLogin = true;
            Cookies.set('userToken', window.name);
            tokenGetUserInfo(window.name).then(result => {
                if (result.code === 200) {
                    this.userInfo = result.user;
                    Cookies.set('userInfo', JSON.stringify(result.user));
                }
            })
        }
        if (Cookies.get('userInfo') && Cookies.get('userToken')) {
            this.isUserLogin = true;
            this.userInfo = JSON.parse(Cookies.get('userInfo'));
        }
    },
    methods: {
        clickRoutr(item) {
            //注意大小写
            if (item.routerName && this.$route.name !== item.routerName && item.routerName !== 'publicServices') {
                // this.actionsIndex = item.index;
                this.$router.push({
                    name: item.routerName
                });
                this.showSelector = false;
            }
            if (item.routerName === 'publicServices') {
                this.actionsIndex = 4;
                this.showSelector = !this.showSelector;
            }
        },
        handleCommand(command) {
            if (command == 'b') {
                if (Cookies.get('userToken')) Cookies.remove('userToken');
                if (Cookies.get('Admin-Token')) Cookies.remove('Admin-Token');
                if (Cookies.get('userInfo')) Cookies.remove('userInfo');
                window.close();
                window.open(baseURL3);
            }
        },
        // 跳转至登录页
        toLogin() {
            if (this.$route.name !== 'Login') {
                window.open(`${baseURL2}/login?source=fromPortals`, '_self');
            }
        },
        toRegister() {
            if (this.$route.name !== 'Register') {
                this.$router.push({
                    name: 'Register'
                });
            }
        },
        openNewPage(num) {
            switch (num) {
                case 1:
                    window.open("https://www.ie-shanghai.com/manage/login/auth1");
                    this.showSelector = false;
                    break;
                case 2:
                    window.open('http://home.c3mep-sh.com:8051/#/DiagnosticInfoEdit');
                    this.showSelector = false;
                    break;
            }
        },
        blurSelector() {
            this.showSelector && (this.showSelector = false);
        }
    }
}
</script>
<style lang="less" scoped>
.el-dropdown-link {
    cursor: pointer;
    color: #868686;
    display: block;
    width: 110px;
    height: 30px;
    line-height: 30px;
    border-radius: 6px;
    border: 1px solid #ededed;
  }
.el-dropdown-menu__item {
    width: 110px;
}
.el-icon-arrow-down {
    font-size: 12px;
}
.box {
    position: relative;
    z-index: 5;
}
.warp {
    width: 100%;
    height: 64px;
    background: #ffffff2b;
    color: #181818;
    box-shadow: 0px 1px 10px 0px #0000000a;
    position: absolute;
    top: 0;
    left: 0;
    backdrop-filter: blur(6px);
}
.flex {
    display: flex;
    justify-content: start;
    align-items: center;
}
.title {
    width: 100%;
    height: 100%;
    .btn {
        margin-left: auto;
        height: 100%;
    }
    .text-login {
        background: linear-gradient(90deg, #1b4596 0%, #a5c642 103.29%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 0 57px 0 19px;
        cursor: pointer;
        font-size: 18px;
        font-weight: 500;
        line-height: 64px;
    }
    .title-btn {
        position: relative;
        .downSelector {
            width: 242px;
            background-color: #1b449693;
            position: absolute;
            top: 30px;
            left: 0;
            box-sizing: border-box;
            padding: 8px;
            overflow: hidden;
            .link {
                color: #fff;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                margin-bottom: 8px;
                display: block;
                text-align: left;
            }
            .link:nth-child(2) {
                margin-bottom: 0;
            }
        }
    }
    .act-btn {
        color: #1b4596 !important;
    }
    .login-btn {
        width: 96px;
        text-align: center;
        background: #1b4596bd;
        color: #ffffff;
        height: 100%;
        line-height: 64px;
        margin-left: 31px;
        cursor: pointer;
    }
    .title-btn {
        margin-right: 46px;
        cursor: pointer;
    }
}
</style>