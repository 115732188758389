// const URL = 'https://www.c3mep-sh.com';   
const URL = 'http://home.c3mep-sh.com:8051';    // 部署

export const baseURL = process.env.VUE_APP_BASE_API;   // 生产环境
// export const baseURL = URL + '/stage-api';  // 开发环境


// 新兴院管理端地址
export const baseURL2 = URL + '/management';

// 新兴院门户前端地址
export const baseURL3 = URL;
