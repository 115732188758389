var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"warp"},[_c('div',{staticClass:"title flex",style:(_vm.isUserLogin && 'padding-right: 20px;')},[_c('div',{staticClass:"text-login"},[_vm._v(" 新型工业化数字基础设施公共服务平台 ")]),_vm._l((_vm.titleArr),function(item,index){return _c('div',{key:index,class:[
                    { 'act-btn': 
                    item.routerName === _vm.$route.name
                    || (item.routerName === 'IndustryClasses' && _vm.$route.name === 'IndustryVideoDetails')
                    || (item.routerName === 'IndustryClasses' && _vm.$route.name === 'IndustrySearch')
                    || (item.routerName === 'ProductSupermarket' && _vm.$route.name === 'ProductInformation')
                },
                    'title-btn'
                ],on:{"click":function($event){return _vm.clickRoutr(item)},"blur":function($event){return _vm.blurSelector()}}},[_vm._v(" "+_vm._s(item.name)+" "),(_vm.showSelector && item.routerName === 'publicServices')?_c('div',{staticClass:"downSelector"},[_c('a',{staticClass:"link",on:{"click":function($event){return _vm.openNewPage(1)}}},[_vm._v("长三角企业数字化转型公共服务平台")]),_c('a',{staticClass:"link",on:{"click":function($event){return _vm.openNewPage(2)}}},[_vm._v("在线自评估")])]):_vm._e()])}),(!_vm.isUserLogin)?_c('div',{staticClass:"btn flex"},[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toLogin()}}},[_vm._v("登录")]),_c('div',{staticClass:"login-btn",on:{"click":function($event){return _vm.toRegister()}}},[_vm._v("免费注册")])]):_vm._e(),(_vm.isUserLogin)?_c('div',{staticClass:"btn flex"},[_c('div',{staticStyle:{"margin-right":"12px"}},[_c('img',{attrs:{"src":_vm.userInfo.avatar ? _vm.userInfo.avatar : require('@/assets/icons/user1.png'),"alt":""}})]),_c('el-dropdown',{on:{"command":_vm.handleCommand}},[_c('span',{staticClass:"el-dropdown-link"},[_c('span',{staticStyle:{"overflow":"hidden"}},[_vm._v(_vm._s(_vm.userInfo.userName || '企业管理员'))]),_c('i',{staticClass:"el-icon-arrow-down el-icon--right"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{staticStyle:{"text-align":"center"},attrs:{"command":"b"}},[_vm._v("登出")])],1)],1)],1):_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }