<template>
    <div id="app">
        <router-view name="sidebar"></router-view>
        <router-view :key="this.$route.fullPath"></router-view>
        <FooterComponent v-if="shouldShowFooter" name="bottomComponents"></FooterComponent>
    </div>
</template>

<script>
import FooterComponent from '@/Sidebar/bottomComponents/index'
export default {
    name: 'App',
    components: { FooterComponent },
    computed: {
        shouldShowFooter() {
            // 检查当前路由，并返回是否应该显示 FooterComponent
            const routeArr = ['DiagnosticEvaluation', 'DiagnosticInfoEdit', 'DiagnosticSuccess', 'ExportPdf'];
            const res = routeArr.filter(ele => this.$route.name === ele);
            return res.length === 0 ? true : false;
        }
    }
}
</script>

<style lang="less">
* {
    margin: 0;
    padding: 0;
}
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin: 0 auto;
    // max-width: 2560px;
    min-width: 1440px;
    // width: 1920px;
    font-size: 14px;
}
input {
    outline: none;
}
</style>
