export default {
  namespaced: true,
  state: {
    // 底部变化
    changeBottom: false,
    productDescribe: ''  // 产品描述
  },
  /* eslint-disable */
  mutations: {
    // 产品描述
    passProductDescribe(state, value) {
      state.productDescribe = value;
    },
    setChangeBottom (state,value) {
      state.changeBottom = value
    }
  },
  
  getters: {
    getChangeBottom() {
      return state.changeBottom
    }
  }
}
