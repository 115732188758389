
import Vue from 'vue'
import VueRouter from 'vue-router'
import Sidebar from '@/Sidebar/headTitle'
Vue.use(VueRouter)


const routes = [
  {
    //redirect重定向
    path: '/', 
    redirect: 'HomePage'
  },
  {
      path: '/HomePage',
      name: 'HomePage',
      components: {
        default: () => import('@/views/HomePage/index.vue'),
        sidebar: Sidebar
      }
  },
  {
      path: '/Register',
      name: 'Register',
      components: {
        default: () => import('@/views/Register/index.vue')
      }
  },
  {
      path: '/RegisterSuccess',
      name: 'RegisterSuccess',
      components: {
        default: () => import('@/views/Register/RegisterSuccess.vue'),
      },
      hidden: true
  },
  // {
  //     path: '/Login',
  //     name: 'Login',
  //     components: {
  //       default: () => import('@/views/Login/index.vue'),
  //     }
  // },
    {
    path: '/ProductSupermarket',
    name: 'ProductSupermarket',
    components: {
      default: () => import('@/views/ProductSupermarket/ProductSupermarket'),
      sidebar: Sidebar
    }
  },
  {
    path: '/ProductInformation',
    name: 'ProductInformation',
        components: {
      default: () => import('@/views/ProductInformation/ProductInformation'),
      sidebar: Sidebar
    }
  },
  {
    path: '/IndustryClasses',
    name: 'IndustryClasses',
    components: {
      default: () => import('@/views/IndustryClasses/IndustryClasses'),
      sidebar: Sidebar
    }
  },
  {
    path: '/PoliciesDynamicState',
    name: 'PoliciesDynamicState',
    components: {
      default: () => import('@/views/PoliciesDynamicState/PoliciesDynamicState'),
      sidebar: Sidebar
    }
  },
  {
    path: '/ProblemsSolution',
    name: 'ProblemsSolution',
    components: {
      default: () => import('@/views/ProblemsSolution/ProblemsSolution'),
      sidebar: Sidebar
    }
  },
  {
    path: '/DiagnosticEvaluation',
    name: 'DiagnosticEvaluation',
    meta: { parent: 'DiagnosticInfoEdit' },
    components: {
      default: () => import('@/views/DiagnosticEvaluation/DiagnosticEvaluation'),
    }
  },
  {
    path: '/DiagnosticInfoEdit',
    name: 'DiagnosticInfoEdit',
    components: {
      default: () => import('@/views/DiagnosticEvaluation/DiagnosticInfoEdit'),
    }
  },
  {
    path: '/DiagnosticSuccess',
    name: 'DiagnosticSuccess',
    meta: { parent: 'DiagnosticInfoEdit' },
    components: {
      default: () => import('@/views/DiagnosticEvaluation/DiagnosticSuccess'),
    }
  },
  {
    path: '/ExportPdf',
    name: 'ExportPdf',
    meta: { parent: 'DiagnosticInfoEdit' },
    components: {
      default: () => import('@/views/DiagnosticEvaluation/evaluation/exportPdf'),
    }
},
  {
    path: '/IndustrySearch/:kw',
    name: 'IndustrySearch',
    meta: { parent: 'IndustryClasses' },
    components: {
      default: () => import('@/views/IndustryClasses/IndustrySearch/IndustrySearch'),
      sidebar: Sidebar
    }
  },
  {
    path: '/IndustryVideoDetails',
    name: 'IndustryVideoDetails',
    meta: { parent: 'IndustryClasses' },
    components: {
      default: () => import('@/views/IndustryClasses/IndustryVideoDetails/IndustryVideoDetails'),
      sidebar: Sidebar
    }
  },
  {
    path: '/FeedBack',
    name: 'FeedBack',
    meta: { parent: 'FeedBack' },
    components: {
      default: () => import('@/views/FeedBack/FeedBack'),
      sidebar: Sidebar
    }
  },
]
const router = new VueRouter({
     routes
})

export default router