<template>
    <div v-if="$route.name !== 'Register' && $route.name !== 'RegisterSuccess'" class="warp">
        <div class="contactInformation">
            <div class="item-left">
                <div class="contactInformation-img">
                    <img :src="logo1" alt="" />
                    <div class="text">
                        政府决策的支撑者 数字设施的运营者 产业创新的推进者
                        技术应用的先行者
                    </div>
                    <div class="email">邮箱：saict@saict.net</div>
                </div>
            </div>
            <!-- <div class="item-right" v-if="BottomDate">
                <div class="text1">联系我们</div>
                <div class="right-hr"></div>
                <div class="text2">新型工业化数字基础设施公共服务平台</div>
                <div class="text3">邮箱：saict@saict.net</div>
            </div> -->
        </div>
        <div class="foot">
            <div>
                2021 - 2024 上海新兴信息通信技术应用研究院 版权所有
                <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">沪ICP备2021025244号-2</a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'bottomComponents',
    computed: {
        BottomDate() {
            return this.$store.state.yrouter.changeBottom
        }
    },
    mounted() {},
    data() {
        return {
            logo1: require('@/assets/img/logo1.png')
        }
    }
}
</script>
<style lang="less" scoped>
.warp {
    width: 100%;
    height: 267px;
}
.contactInformation {
    height: 237px;
    background-image: url('@/assets/img/bgimg2.png');
    background-repeat: round;
    width: 100%;
    text-align: left;
    display: flex;
    justify-content: space-between;
    .item-left {
        width: 1280px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .item-right {
        .text1 {
            margin: 59px 287px 0 0;
            color: #4b5b76;
            font-size: 28px;
            font-weight: 500;
        }
        .text2 {
            color: #4b5b76;
            font-size: 14px;
            font-weight: 500;
        }
        .text3 {
            color: #4b5b76;
            font-size: 14px;
            font-weight: 500;
            margin-top: 10px;
        }
        .right-hr {
            width: 255px;
            height: 3px;
            background-color: #4b5b76;
            margin: 14px 0;
        }
    }
    .contactInformation-img {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .text {
        margin-top: 26px;
        color: #4b5b76;
    }
    .email {
        margin-top: 15px;
        text-align: center;
        color: #4b5b76;
    }
}
.foot {
    background: #1b1f2dbd;
    height: 60px;
    width: 100%;
    text-align: center;
    line-height: 60px;
    color: #fff;
}
</style>