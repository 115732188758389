// rem等比适配配置文件
// 基准大小
const baseSize = 16;
// 设置 rem 函数
function setRem() {
  function pxSet(_Width) {
    const scale = _Width / 1366;
    // 设置页面根节点字体大小（“Math.min(scale, 3)” 指最高放大比例为3，可根据实际业务需求调整）
    document.documentElement.style.fontSize = `${baseSize * Math.min(scale, 3)}px`;
  }
  // 当前页面屏幕分辨率相对于 1280宽的缩放比例，可根据自己需要修改
   if (document.documentElement.clientWidth > 1921) {
    pxSet(1920) 
  }else if (document.documentElement.clientWidth< 1439) {
    pxSet(1440) 
  }else if (document.documentElement.clientWidth < 1921 && document.documentElement.clientWidth > 1439) {
    pxSet(document.documentElement.clientWidth) 
  } 
  
}
// 初始化
setRem();
// 改变窗口大小时重新设置 rem
window.onresize = () => {
  setRem();
};
