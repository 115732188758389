import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
// import user from './modules/user'
// import permission from './modules/permission'
import yrouter from './modules/yrouter'
import getters from './getters'


// 长沙原先代码，暂时注释
// const files = require.context('./modules', false, /\.js$/)
// const modules = {}

// files.keys().forEach(key => {
//   modules[key.replace(/(\.\/|\.js)/g, '')] = files(key).default
// })


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    yrouter, 
    app,
    // user,
    // permission
  },
  getters
})
